import cogoToast from "cogo-toast";
import { phoneNumberFormatE164 } from "helper";
import NewCallItem from "pages/dashboard/dashboard-users/call-list/call-item-new";
import CallItem from "pages/dashboard/dashboard-users/call-list/call-item/CallItem";

import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

const useMemoizedCallItems = (callsData, timersHook, display, handleRecord, handleBridgeCall) => {
  const lookups = useSelector((state) => state.lookups.items);

  const lookupMap = useMemo(() => {
    return lookups.reduce((acc, lookup) => {
      if (lookup.HomePhone) acc[lookup.HomePhone] = lookup;
      if (lookup.Phone2) acc[lookup.Phone2] = lookup;
      if (lookup.Phone3) acc[lookup.Phone3] = lookup;
      return acc;
    }, {});
  }, [lookups]);

  const memoizedCallItems = useMemo(() => {
    return callsData.map((item) => {
      const callerNumber = phoneNumberFormatE164(item.callerIDNumber);

      const lookup = lookupMap[callerNumber];

      return (
        <div className="relative group cursor-pointer" onClick={()=>{
          if(lookup){
            let url = ''
            if(lookup.RecordType && lookup.RecordType === "Caregiver"){
              url = `https://app.hhaexchange.com/ENT2410010000/Aide/Aide.aspx?AideId=${lookup.ID}`
            }
            else if(lookup.RecordType && lookup.RecordType === "Patient"){
              url = `https://app.hhaexchange.com/ENT2410010000/Patient/InternalPatientInfo.aspx?PatientId=${lookup.ID}`
            }
            window.open(url, '_blank')
          }
        }}>
          <NewCallItem
            display={display}
            key={item.callUUID}
            time={timersHook[item?.callUUID] ? timersHook[item?.callUUID] : "00:00"}
            phone={item.callerIDNumber}
            name={item.callerIDName}
            info={item}
            status={item.direction}
            handleRecord={handleRecord}
            handleBridgeCall={handleBridgeCall}
          />
          {lookup ? (
            <div
              className="absolute left-0 mt-2  rounded-md shadow-lg bg-white p-2 text-gray-700 text-sm 
               opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"
              style={{
                width: "350px",
              }}
            >
              {Object.keys(lookup).map((key) => (
                <p
                  key={key}
                  onClick={() => {
                    navigator.clipboard.writeText(lookup[key]);
                    cogoToast.success("Copied to clipboard");
                  }}
                >
                  <strong>{key}:</strong> <span className="text-blue-400 cursor-pointer">{lookup[key]}</span>
                </p>
              ))}
            </div>
          ) : (
            <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white p-2 text-gray-700 text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">No lookup</div>
          )}
        </div>
      );
    });
  }, [callsData, timersHook]);

  return memoizedCallItems;
};

export default useMemoizedCallItems;
