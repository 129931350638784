import React, { useState } from "react";

import OnCallsList from "../call-list/OnCallsList";

import AvailableUserItem from "./available-user-item";

import AvailableUsersList from "./AvailableUsersList";

const NewUsersList = ({ handleBridgeCall }) => {
  const [sortBy, setSortBy] = useState("extensionAsscending");
  const [sortByOnCall, setSortByOnCall] = useState("extensionAsscending");
  const [searchInputOnCallList, setSearchInputOnCallList] = useState("");
  const [searchInputAvailableList, setSearchInputAvailableList] = useState("");

  return (
    <div class="flex-[1_5_6%] overflow-auto touch-pan-x">
      <div class="sidebar-Right py-3 px-5 h-full">
        <h3 class="font-medium text-base">Users online</h3>

        <div class="pt-4">
          <p class="text-xs font-semibold">ON CALLS</p>
          <select
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "999px",
              fontSize: "0.875rem",
              fontWeight: "500",
              cursor: "pointer",
              border: "1px solid #CCCCCC",
              backgroundColor: "rgb(238 238 238 / var(--tw-bg-opacity))",
              width: "100%",
            }}
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSortByOnCall(e.target.value)}
          >
            <option value="extensionAsscending">Extension (Ascending)</option>
            <option value="extensionDescending">Extension (Descending)</option>
            <option value="aToZ">Name (Ascending)</option>
            <option value="bToZ">Name (Descending)</option>
          </select>
          <input
            type="text"
            placeholder="Search"
            className="focus:outline-none text-xs font-medium mt-1"
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "999px",
              fontSize: "0.875rem",
              fontWeight: "500",
              cursor: "pointer",
              border: "1px solid #CCCCCC",
              backgroundColor: "rgb(238 238 238 / var(--tw-bg-opacity))",
              width: "100%",
            }}
            onChange={(event) => {
              setSearchInputOnCallList(event.target.value);
            }}
            value={searchInputOnCallList}
          />
          <OnCallsList
            sortBy={sortByOnCall}
            searchInputOnCallList={searchInputOnCallList}
          />
        </div>

        <div class="pt-6">
          <p class="text-xs font-semibold">AVAILABLE</p>
          <select
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "999px",
              fontSize: "0.875rem",
              fontWeight: "500",
              cursor: "pointer",
              border: "1px solid #CCCCCC",
              backgroundColor: "rgb(238 238 238 / var(--tw-bg-opacity))",
              width: "100%",
            }}
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setSortBy(e.target.value)}
          >
            {" "}
            <option value="extensionAsscending">Extension (Asscending)</option>
            <option value="extensionDescending">Extension (Descending)</option>
            <option value="aToZ">Name (Ascending)</option>
            <option value="bToZ">Name (Descending)</option>
          </select>
          <input
            type="text"
            placeholder="Search"
            className="focus:outline-none text-xs font-medium mt-1"
            style={{
              padding: "0.5rem 1rem",
              borderRadius: "999px",
              fontSize: "0.875rem",
              fontWeight: "500",
              cursor: "pointer",
              border: "1px solid #CCCCCC",
              backgroundColor: "rgb(238 238 238 / var(--tw-bg-opacity))",
              width: "100%",
            }}
            onChange={(event) => {
              setSearchInputAvailableList(event.target.value);
            }}
            value={searchInputAvailableList}
          />
          <AvailableUsersList
            handleBridgeCall={handleBridgeCall}
            searchInputAvailableList={searchInputAvailableList}
            sortBy={sortBy}
          />
        </div>
      </div>
    </div>
  );
};

export default NewUsersList;
