import { useSelector } from "react-redux";

import useCallTimers from "hooks/useCallTimers";

import useMemoizedCallItems from "hooks/useMemoizedCallItems";

const CallsList = ({
  display,
  searchInput,
  handleRecord,
  handleBridgeCall,
}) => {
  const ivrMenus = useSelector((state) => state.ivrMenus.items);
  const ringGroups = useSelector((state) => state.ringGroups.items);
  const extensions = useSelector((state) => state.extensions.items);
  const callsData = useSelector((state) => state.callDetails.data);
  const timersHook = useCallTimers(callsData);
  let dt = callsData.map((item) => {
    if (ivrMenus?.result?.some((menu) => menu.extension == item.destination)) {
      let ivr = ivrMenus.result.filter(
        (menu) => menu.extension == item.destination
      );
      if (ivr[0]) {
        return { ...item, infoName: ivr[0].name };
      }
      return { ...item, infoName: ivr[0].name };
    } else if (
      ringGroups?.result?.some((ring) => ring.extension == item.destination)
    ) {
      let ringGroup = ringGroups?.result?.filter(
        (ring) => ring.extension == item.destination
      );
      if (ringGroup[0]) {
        return { ...item, infoName: ringGroup[0].name };
      }
    } else if (
      extensions?.result?.some((ext) => ext.extension == item.destination)
    ) {
      let extension = extensions?.result?.filter(
        (ext) => ext.extension == item.destination
      );
      if (extension[0]) {
        return { ...item, infoName: extension[0].name };
      }
    } else {
      return { ...item, infoName: item.callerIDName };
    }
  });

  const filteredData = dt.filter((item) => {
    if (item) {
      const searchPattern = new RegExp(searchInput, "i");
      return (
        searchPattern.test(item?.infoName) ||
        searchPattern.test(item.callerIDName) ||
        searchPattern.test(item.callerIDNumber) ||
        searchPattern.test(item?.destination)
      );
    }
  });
  const memoizedCallItems = useMemoizedCallItems(
    filteredData,
    timersHook,
    display,
    handleRecord,
    handleBridgeCall
  );
  return memoizedCallItems;
};

export default CallsList;
