import { api } from "@data-phone/react-resource-server-auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { phoneNumberFormatE164 } from "helper";
import qs from "qs";

const initialState = {
  items: [],
  status: "idle",
  error: null,
};

export const fetchLookupsSlice = createAsyncThunk("items/fetchLookupsSlice", async (data) => {
  const queryString = Array.isArray(data.number)
    ? `?${qs.stringify({ numbers: data.number }, { indices: false })}`
    : data.number;

  const response = await api.get(`${process.env.REACT_APP_API_BASE_URL}/integrations/${data.integration}/lookup/${queryString}`);
  return response.data;
});

const lookupsSlice = createSlice({
  name: "lookups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLookupsSlice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLookupsSlice.fulfilled, (state, action) => {
        state.status = "succeeded";
        if(Array.isArray(action.payload)) {
          state.items = action.payload.map((item) => {
            return {
              ...item,
              HomePhone: phoneNumberFormatE164(item.HomePhone),
              Phone2: phoneNumberFormatE164(item.Phone2),
              Phone3: phoneNumberFormatE164(item.Phone3),
            };
          });
        }
        if (action.payload && !state.items.some((item) => item.HomePhone === action.payload.HomePhone || item.Phone2 === action.payload.Phone2 || item.Phone3 === action.payload.Phone3)) {
          state.items = [...state.items, {
            ...action.payload,
            HomePhone: phoneNumberFormatE164(action.payload.HomePhone),
            Phone2: phoneNumberFormatE164(action.payload.Phone2),
            Phone3: phoneNumberFormatE164(action.payload.Phone3),
          }];
        }
      })
      .addCase(fetchLookupsSlice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default lookupsSlice.reducer;
