const expirationMiddleware = (store) => (next) => (action) => {
    const expirationKey = 'lookups_expiration';
    const expirationTime = localStorage.getItem(expirationKey);
    const currentTime = new Date().getTime();
  
    if (expirationTime && currentTime > expirationTime) {
      localStorage.removeItem('persist:root');
      localStorage.removeItem(expirationKey); 
    }
  
    return next(action);
  };
  
  export default expirationMiddleware;