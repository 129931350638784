// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for the web
import expirationMiddleware from '../middleware/index';
import rootReducer from '../slices/appSlice';

const PERSIST_EXPIRATION_TIME = 6 * 60 * 60 * 1000; // 24 hours

const persistConfig = {
  key: 'root',
  storage,
  // Add any other config options here
  whitelist: ['lookups'], 
  transforms: [
    {
      out: (state) => {
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + PERSIST_EXPIRATION_TIME;
        localStorage.setItem('lookups_expiration', expirationTime);
        return state;
      },
    },
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Add any other store configuration here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(expirationMiddleware),
});

const persistor = persistStore(store);

export { store, persistor };
